export default {
	path: '/dashboard',
	redirect: '/dashboard',
	component: () => import('@/views/layout/Dashboard'),
	children: [
		{
			path: '/',
			name: 'dashboardHome',
			component: () => import('@/views/dashboard/Dashboard'),
		},
	],
};

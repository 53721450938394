export default {
	path: '/object',
	redirect: '/object',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'objectHome',
			component: () => import('@/views/mng/Object'),
		},
	],
};

export default {
	path: '/ai-config-distigsh-sutb-dgre-cctv',
	redirect: '/ai-config-distigsh-sutb-dgre-cctv',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'AiConfigDistigshSutbDgreCctvHome',
			component: () => import('@/views/mng/AiConfigDistigshSutbDgreCctv'),
		},
	],
};

export default {
	path: '/kccRestApi/*',
	component: () => import('@/views/layout/Default.empty'),
	children: [
		{
			path: '/',
			name: 'kccRestApiLogin',
			component: () => import('@/views/main/kccRestApiLogin'),
		},
	],
};

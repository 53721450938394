export default {
	path: '/danger-occur',
	redirect: '/danger-occur',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'DangerOccurHome',
			component: () => import('@/views/biz/DangerOccur'),
		},
	],
};

export default {
	path: '/cctv-by-safe-manager',
	redirect: '/cctv-by-safe-manager',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'cctvBySafeManagerHome',
			component: () => import('@/views/mng/CctvBySafeManager'),
		},
	],
};

import Vue from 'vue';
import VueCookies from 'vue-cookies';
import App from './App.vue';
import plugins from '@/plugins';
import router from './router';
import store from './store';
import interceptor from './lib/interceptor';
import axios from 'axios';
import jarvisExtention from './lib/JarvisExtention';
import VeeValidate from 'vee-validate';
import validateExtend from './lib/vue.extend';
import ko from 'vee-validate/dist/locale/ko';
import CKEditor from 'ckeditor4-vue';

Vue.use(CKEditor);
Vue.use(interceptor.axios);

const config = {
	locale: 'ko',
	dictionary: {
		ko,
	},
};

Vue.use(validateExtend);
Vue.use(VeeValidate, config);

// Vue 전역에서 this.$axios로 axios 사용할 수 있게 세팅
Vue.prototype.$axios = axios;
Vue.prototype.$jarvisExtention = jarvisExtention;

Vue.config.productionTip = false;

Vue.use(plugins);
Vue.use(VueCookies);
Vue.$cookies.config('idsave');
Vue.$cookies.config('V2_ACCESS_TOKEN');

new Vue({
	router,
	store,
	render: h => h(App),
}).$mount('#app');

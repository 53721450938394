export default {
	path: '/speaker-soundsrc',
	redirect: '/speaker-soundsrc',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'speakerSoundsrcHome',
			component: () => import('@/views/mng/SpeakerSoundsrc'),
		},
	],
};

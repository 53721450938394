import localConfig from '../config/local';

// api call base url
const baseUrl = localConfig['api.baseUri'];
const mainUrl = localConfig['web.mainUri'];

export default {
	mainUrl: mainUrl,
	baseUrl: baseUrl,
	cryptSecretKey: 'Aikl-v2-Parameter-Crypt-Key',
	comm: {
		inqCompanyFindBySiteId: baseUrl + '/inqCompanyFindBySiteId.do',
		inqCctvFindBySiteId: baseUrl + '/inqCctvFindBySiteId.do',
	},
	main: {
		mainInfo: baseUrl + '/mainInfo.do',
		getWeatherInfo: baseUrl + '/inqSiteCoordinate.do',
		inqCurrentAccidentPrevent: baseUrl + '/inqCurrentAccidentPrevent.do',
		//건설용
		inqDayChart: baseUrl + '/inqDayChart.do',
		inqWeekChart: baseUrl + '/inqWeekChart.do',
		inqMonthChart: baseUrl + '/inqMonthChart.do',
		//
		//제철용
		inqDayChartForIron: baseUrl + '/inqDayChartForIron.do',
		inqWeekChartForIron: baseUrl + '/inqWeekChartForIron.do',
		inqMonthChartForIron: baseUrl + '/inqMonthChartForIron.do',
		//
		inqDangerOccurReport: baseUrl + '/inqDangerOccurReport.do',
		imgRealtimeEvent: baseUrl + '/imgRealtimeEvent.do',
		inqRecentlyNotice: baseUrl + '/inqRecentlyNotice.do',
		userInfo: baseUrl + '/main/userInfo.do',
		occurEvent: baseUrl + '/main/occurEvent.do',
		workReporting: baseUrl + '/main/workReporting.do',
		dangerOccurType: baseUrl + '/main/dangerOccurType.do',
		recentDangerOccur: baseUrl + '/main/recentDangerOccur.do',
		todayWarnWorkOccur: baseUrl + '/main/todayWarnWorkOccur.do',
		readImage: baseUrl + '/main/readImage.do',
	},
	login: {
		loginUserChk: baseUrl + '/login.do',
		inqUserSite: baseUrl + '/inqUserSite.do',
		setUserSiteId: baseUrl + '/setUserSiteId.do',
		loginProcess: baseUrl + '/loginProcess',
		insRegisterUser: baseUrl + '/insRegisterUser.do',
		inqChangeSiteIds: baseUrl + '/inqChangeSiteIds.do',
	},
	dashboard: {
		viewDashboard: baseUrl + '/viewDashboard.do',
		inqCctv: baseUrl + '/dashboard/cctv.do',
		reload: baseUrl + '/dashboard/reload.do',
		popupImage: baseUrl + '/dashboard/popupImage.do',
		inqEventImages: baseUrl + '/dashboard/inqEventImages.do',
		readImage: baseUrl + '/dashboard/readImage.do',
		readThumImage: baseUrl + '/dashboard/readThumImage.do',
		inqCctvGrp: baseUrl + '/dashboard/inqCctvGrp.do',
	},
	cctvGrp: {
		viewCctvGrp: baseUrl + '/viewCctvGrp.do',
		inqCctvGrp: baseUrl + '/inqCctvGrp.do',
		inqOneCctvGrp: baseUrl + '/inqOneCctvGrp.do',
		insCctvGrp: baseUrl + '/insCctvGrp.do',
		updCctvGrp: baseUrl + '/updCctvGrp.do',
		delCctvGrp: baseUrl + '/delCctvGrp.do',
		inqCctvGrpRole: baseUrl + '/inqCctvGrpRole.do',
		insCctvGrpRole: baseUrl + '/insCctvGrpRole.do',
		updCctvGrpRole: baseUrl + '/updCctvGrpRole.do',
		delCctvGrpRole: baseUrl + '/delCctvGrpRole.do',
	},
	accidentPrevent: {
		viewAccidentPrevent: baseUrl + '/viewAccidentPrevent.do',
		inqAccidentPrevent: baseUrl + '/inqAccidentPrevent.do',
		inqOneAccidentPrevent: baseUrl + '/inqOneAccidentPrevent.do',
		inqAccidentPreventFindByAccdtPrvtnActnReqSeq: baseUrl + '/inqAccidentPreventFindByAccdtPrvtnActnReqSeq.do',
		imgOneAccidentPrevent: baseUrl + '/imgOneAccidentPrevent.do/',
		exlAccidentPrevent: baseUrl + '/exlAccidentPrevent.do',
		aiAccidentPrevent: baseUrl + '/aiAccidentPrevent.do/',
		uplAccidentPrevent: baseUrl + '/uplAccidentPrevent.do',
		insAccidentPrevent: baseUrl + '/m/insAccidentPrevent.do',
		reInsAccidentPrevent: baseUrl + '/m/reInsAccidentPrevent.do',
		mUpdAccidentPrevent: baseUrl + '/m/updAccidentPrevent.do',
		updAccidentPrevent: baseUrl + '/updAccidentPrevent.do',
		delAccidentPrevent: baseUrl + '/delAccidentPrevent.do',
		inqPopupAccidentPreventTargetByObjectId: baseUrl + '/inqPopupAccidentPreventTargetByObjectId.do',
		accidentPreventInsExemplaryCase: baseUrl + '/accidentPrevent/insExemplaryCase.do',
	},
	warnWorkOccur: {
		viewWarnWorkOccur: baseUrl + '/viewWarnWorkOccur.do',
		viewWarnWorkOccurVideo: baseUrl + '/viewWarnWorkOccurVideo.do',
		inqWarnWorkOccur: baseUrl + '/inqWarnWorkOccur.do',
		inqOneWarnWorkOccur: baseUrl + '/inqOneWarnWorkOccur.do',
		updWarnWorkOccurMisdetection: baseUrl + '/updWarnWorkOccurMisdetection.do',
		updWarnWorkOccurDeMisdetection: baseUrl + '/updWarnWorkOccurDeMisdetection.do',
		inqWarnWorkOccurCctvInfo: baseUrl + '/inqWarnWorkOccurCctvInfo.do',
		inqOneWarnWorkOccurVideo: baseUrl + '/inqOneWarnWorkOccurVideo.do',
		sendMakeVideo: baseUrl + '/sendMakeWarnWorkVideo.do',
		imgOneWarnWorkOccur: baseUrl + '/imgOneWarnWorkOccur.do',
	},
	dangerOccur: {
		viewDangerOccur: baseUrl + '/viewDangerOccur.do',
		viewDangerOccurVideo: baseUrl + '/viewDangerOccurVideo.do',
		inqDangerOccurTargetInfo: baseUrl + '/inqDangerOccurTargetInfo.do',
		inqDangerOccur: baseUrl + '/inqDangerOccur.do',
		inqOneDangerOccur: baseUrl + '/inqOneDangerOccur.do',
		imgOneDangerOccur: baseUrl + '/imgOneDangerOccur.do',
		inqImgDetailsInfo: baseUrl + '/inqImgDetailsInfo.do',
		inqDangerOccurCctvInfo: baseUrl + '/inqDangerOccurCctvInfo.do',
		inqClickFrameImgDetail: baseUrl + '/inqClickFrameImgDetail.do',
		dangerOccurTakingAction: baseUrl + '/dangerOccurTakingAction.do',
		dangerOccurMisdetection: baseUrl + '/dangerOccurMisdetection.do',
		dangerOccurDeMisdetection: baseUrl + '/dangerOccurDeMisdetection.do',
		inqOneDangerOccurVideo: baseUrl + '/inqOneDangerOccurVideo.do',
		sendMakeVideo: baseUrl + '/sendMakeDangerVideo.do',
	},
	dangerWarnWorkOccur: {
		viewDangerWarnWorkOccur: baseUrl + '/viewDangerWarnWorkOccur.do',
		inqDangerWarnWorkOccur: baseUrl + '/inqDangerWarnWorkOccur.do',
		ingOneObjectTargetInfo: baseUrl + '/ingOneObjectTargetInfo.do',
		ingAllObjectTargetInfo: baseUrl + '/ingAllObjectTargetInfo.do',
	},
	aiDistinguish: {
		viewAiDistinguish: baseUrl + '/viewAiDistinguish.do',
		inqAiDistinguish: baseUrl + '/inqAiDistinguish.do',
		readAllInferImage: baseUrl + '/readAllInferImage.do',
		aiDistinguishMisdetection: baseUrl + '/aiDistinguishMisdetection.do',
		aiDistinguishDeMisdetection: baseUrl + '/aiDistinguishDeMisdetection.do',
	},
	siteStatistic: {
		viewSiteStatistic: baseUrl + '/viewSiteStatistic.do',
		inqSiteStatistic: baseUrl + '/inqSiteStatistic.do',
		inqOneSiteStatistic: baseUrl + '/inqOneSiteStatistic.do',
		exlSiteStatistic: baseUrl + '/exlSiteStatistic.do',
	},
	accidentStatistic: {
		viewAccidentStatistic: baseUrl + '/viewAccidentStatistic.do',
		inqAccidentStatistic: baseUrl + '/inqAccidentStatistic.do',
		inqOneAccidentStatistic: baseUrl + '/inqOneAccidentStatistic.do',
		exlAccidentStatistic: baseUrl + '/exlAccidentStatistic.do',
	},
	objectDangerOccurStatistic: {
		viewObjectDangerOccurStatistic: baseUrl + '/viewObjectDangerOccurStatistic.do',
		viewObjectDangerOccurStatisticBar: baseUrl + '/viewObjectDangerOccurStatisticBar.do',
		viewObjectDangerOccurStatisticBar2: baseUrl + '/viewObjectDangerOccurStatisticBar2.do',
		viewObjectDangerOccurStatisticLine2: baseUrl + '/viewObjectDangerOccurStatisticLine2.do',
		inqObjectDangerOccurStatistic: baseUrl + '/inqObjectDangerOccurStatistic.do',
		inqObjectDangerOccurStatisticLineChart: baseUrl + '/inqObjectDangerOccurStatisticLineChart.do',
		inqObjectDangerOccurStatisticLineByTheHour: baseUrl + '/inqObjectDangerOccurStatisticLineByTheHour.do',
		inqObjectDangerOccurStatisticLineByDate: baseUrl + '/inqObjectDangerOccurStatisticLineByDate.do',
		inqDangerBarChart: baseUrl + '/inqDangerBarChart.do',
		inqChartObjectTargetList: baseUrl + '/inqChartObjectTargetList.do',
	},
	objectWarnWorkOccurStatistic: {
		viewObjectWarnWorkOccurStatisticBar: baseUrl + '/viewObjectWarnWorkOccurStatisticBar.do',
		inqWarnWorkBarChart: baseUrl + '/inqWarnWorkBarChart.do',
	},
	exemplaryCase: {
		viewExemplaryCase: baseUrl + '/viewExemplaryCase.do',
		imgMain: baseUrl + '/imgExemplaryCase.do/',
		inqExemplaryCase: baseUrl + '/inqExemplaryCase.do',
		inqOneExemplaryCase: baseUrl + '/inqOneExemplaryCase.do',
		exlExemplaryCase: baseUrl + '/exlExemplaryCase.do',
		inqExemplaryCaseObject: baseUrl + '/inqExemplaryCaseObject.do',
		inqExemplaryCaseTarget: baseUrl + '/inqExemplaryCaseTarget.do',
		chkExemplaryCase: baseUrl + '/chkExemplaryCase.do',
		uplExemplaryCase: baseUrl + '/uplExemplaryCase.do',
		insExemplaryCase: baseUrl + '/insExemplaryCase.do',
		updExemplaryCase: baseUrl + '/updExemplaryCase.do',
		delExemplaryCase: baseUrl + '/delExemplaryCase.do',
	},
	site: {
		viewSite: baseUrl + '/viewSite.do',
		inqSite: baseUrl + '/inqSite.do',
		inqOneSite: baseUrl + '/inqOneSite.do',
		exlSite: baseUrl + '/exlSite.do',
		delSite: baseUrl + '/delSite.do',
		updSite: baseUrl + '/updSite.do',
		insSite: baseUrl + '/insSite.do',
		inqSiteSupervisorByUserId: baseUrl + '/inqSiteSupervisorByUserId.do',
	},
	company: {
		viewCompany: baseUrl + '/viewCompany.do',
		inqCompany: baseUrl + '/inqCompany.do',
		exlCompany: baseUrl + '/exlCompany.do',
		inqOneCompany: baseUrl + '/inqOneCompany.do',
		inqCheckCompanyNm: baseUrl + '/inqCheckCompanyNm.do',
		insCompany: baseUrl + '/insCompany.do',
		updCompany: baseUrl + '/updCompany.do',
		delCompany: baseUrl + '/delCompany.do',
	},
	user: {
		viewUser: baseUrl + '/viewUser.do',
		exlUser: baseUrl + '/exlUser.do',
		inqUser: baseUrl + '/inqUser.do',
		inqOneUserWithRoleGroup: baseUrl + '/inqOneUserWithRoleGroup.do',
		inqCheckCompanyManager: baseUrl + '/inqCheckCompanyManager.do',
		updUser: baseUrl + '/updUser.do',
		delUser: baseUrl + '/delUser.do',
		insUser: baseUrl + '/insUser.do',
		inqCheckUserId: baseUrl + '/inqCheckUserId.do',
		inqCheckHpNo: baseUrl + '/inqCheckHpNo.do',
	},
	menu: {
		viewMenu: baseUrl + '/viewMenu.do',
		inqMenu: baseUrl + '/inqMenu.do',
		exlMenu: baseUrl + '/exlMenu.do',
		menu: baseUrl + '/menu.do',
	},
	roleGroup: {
		viewRoleGroup: baseUrl + '/viewRoleGroup.do',
		inqRoleGroup: baseUrl + '/inqRoleGroup.do',
		exlRoleGroup: baseUrl + '/exlRoleGroup.do',
		roleGroup: baseUrl + '/roleGroup.do',
	},
	grpCode: {
		viewGrpCode: baseUrl + '/viewGrpCode.do',
		inqGrpCode: baseUrl + '/inqGrpCode.do',
		grpCode: baseUrl + '/grpCode.do',
		exlGrpCode: baseUrl + '/exlGrpCode.do',
	},
	commCode: {
		viewCommCode: baseUrl + '/viewCommCode.do',
		inqCommCode: baseUrl + '/inqCommCode.do',
		inqOneCommCode: baseUrl + '/inqOneCommCode.do',
		delCommCode: baseUrl + '/delCommCode.do',
		exlCommCode: baseUrl + '/exlCommCode.do',
		updCommCode: baseUrl + '/updCommCode.do',
		commCode: baseUrl + '/commCode.do',
		inqCommCodeAllList: baseUrl + '/inqCommCodeAllList.do',
	},
	object: {
		viewObject: baseUrl + '/viewObject.do',
		inqObject: baseUrl + '/inqObject.do',
		exlObject: baseUrl + '/exlObject.do',
		object: baseUrl + '/object.do',
	},
	target: {
		viewTarget: baseUrl + '/viewTarget.do',
		inqTarget: baseUrl + '/inqTarget.do',
		target: baseUrl + '/target.do',
		exlTarget: baseUrl + '/exlTarget.do',
	},
	objectTarget: {
		viewObjectTarget: baseUrl + '/viewObjectTarget.do',
		inqObjectTarget: baseUrl + '/inqObjectTarget.do',
		exlObjectTarget: baseUrl + '/exlObjectTarget.do',
		objectTarget: baseUrl + '/objectTarget.do',
	},
	warnWork: {
		viewWarnWork: baseUrl + '/viewWarnWork.do',
		inqWarnWork: baseUrl + '/inqWarnWork.do',
		inqOneWarnWork: baseUrl + '/inqOneWarnWork.do',
		exlWarnWork: baseUrl + '/exlWarnWork.do',
		delWarnWork: baseUrl + '/delWarnWork.do',
		insWarnWork: baseUrl + '/insWarnWork.do',
		updWarnWork: baseUrl + '/updWarnWork.do',
	},
	cctv: {
		viewCctv: baseUrl + '/viewCctv.do',
		inqCctv: baseUrl + '/inqCctv.do',
		inqOneCctv: baseUrl + '/inqOneCctv.do',
		delCctv: baseUrl + '/delCctv.do',
		exlCctv: baseUrl + '/exlCctv.do',
		insCctv: baseUrl + '/insCctv.do',
		updCctv: baseUrl + '/updCctv.do',
		inqMlEngineInfo: baseUrl + '/inqMlEngineInfo.do',
		inqMlConnectedCctv: baseUrl + '/inqMlConnectedCctv.do',
		insMlEngineInfo: baseUrl + '/insMlEngineInfo.do',
		updMlEngineInfo: baseUrl + '/updMlEngineInfo.do',
		delMlEngineInfo: baseUrl + '/delMlEngineInfo.do',
	},
	basicAiConfig: {
		viewBasicAiConfig: baseUrl + '/viewBasicAiConfig.do',
		inqAiConfigAll: baseUrl + '/inqAiConfigAll.do',
		inqAiConfigAllWarnWork: baseUrl + '/inqAiConfigAllWarnWork.do',
		inqAiConfigAllDanger: baseUrl + '/inqAiConfigAllDanger.do',
		saveAiConfigAllWarnWork: baseUrl + '/saveAiConfigAllWarnWork.do',
		saveAiConfigAllDanger: baseUrl + '/saveAiConfigAllDanger.do',
	},
	cctvAiConfig: {
		viewCctvAiConfig: baseUrl + '/viewCctvAiConfig.do',
		inqAiConfigCctvInfo: baseUrl + '/inqAiConfigCctvInfo.do',
		inqAiConfigObjectOnlyCctv: baseUrl + '/inqAiConfigObjectOnlyCctv.do',
		inqAiConfigWarnWorkCctv: baseUrl + '/inqAiConfigWarnWorkCctv.do',
		inqAiConfigDangerCctv: baseUrl + '/inqAiConfigDangerCctv.do',
		saveAiConfigObjectOnlyCctv: baseUrl + '/saveAiConfigObjectOnlyCctv.do',
		saveAiConfigWarnWorkCctv: baseUrl + '/saveAiConfigWarnWorkCctv.do',
		saveAiConfigDangerCctv: baseUrl + '/saveAiConfigDangerCctv.do',
	},
	apiCallErrLog: {
		viewApiCallErrLog: baseUrl + '/viewApiCallErrLog.do',
		inqApiCallErrLog: baseUrl + '/inqApiCallErrLog.do',
		inqOneApiCallErrLog: baseUrl + '/inqOneApiCallErrLog.do',
		exlApiCallErrLog: baseUrl + '/exlApiCallErrLog.do',
	},
	notice: {
		viewNotice: baseUrl + '/viewNotice.do',
		inqNotice: baseUrl + '/inqNotice.do',
		inqOneNotice: baseUrl + '/inqOneNotice.do',
		uplNotice: baseUrl + '/uplNotice.do',
		insNotice: baseUrl + '/insNotice.do',
		fileNotice: baseUrl + '/fileNotice.do/',
		updNotice: baseUrl + '/updNotice.do',
		delNotice: baseUrl + '/delNotice.do',
	},
	tree: {
		viewTree: baseUrl + '/viewTree.do',
		inqSiteInfoAndCompanyId: baseUrl + '/inqSiteInfoAndCompanyId.do',
		inqUserInfo: baseUrl + '/inqUserInfo.do',
		inqSiteSafetySupervisorInfo: baseUrl + '/inqSiteSafetySupervisorInfo.do',
	},
	cctvDangerAreaConfig: {
		viewCctvDangerAreaConfig: baseUrl + '/viewCctvDangerAreaConfig.do',
		inqSiteInfoAndCctvInfo: baseUrl + '/inqSiteInfoAndCctvInfo.do',
		insPolygonDatas: baseUrl + '/insPolygonDatas.do',
		inqCctvDangerArea: baseUrl + '/inqCctvDangerArea.do',
	},
	siteTrespassConfig: {
		viewSiteTrespassConfig: baseUrl + '/viewSiteTrespassConfig.do',
		inqSiteTrespassConfig: baseUrl + '/inqSiteTrespassConfig.do',
		insSiteTrespassConfig: baseUrl + '/insSiteTrespassConfig.do',
		updSiteTrespassConfig: baseUrl + '/updSiteTrespassConfig.do',
		delSiteTrespassConfig: baseUrl + '/delSiteTrespassConfig.do',
		inqSiteTrespassAiConfig: baseUrl + '/inqSiteTrespassAiConfig.do',
		updSiteTrespassAiConfig: baseUrl + '/updSiteTrespassAiConfig.do',
	},
	settingPushAlarm: {
		viewSettingPushAlarm: baseUrl + '/viewSettingPushAlarm.do',
		inqSafetySupervisorInfo: baseUrl + '/inqSafetySupervisorInfo.do',
		inqOnePushSetting: baseUrl + '/inqOnePushSetting.do',
		updPushSetting: baseUrl + '/updPushSetting.do',
	},
	speaker: {
		viewSpeaker: baseUrl + '/viewSpeaker.do',
		inqSpeaker: baseUrl + '/inqSpeaker.do',
		inqOneSpeaker: baseUrl + '/inqOneSpeaker.do',
		insSpeaker: baseUrl + '/insSpeaker.do',
		updSpeaker: baseUrl + '/updSpeaker.do',
		delSpeaker: baseUrl + '/delSpeaker.do',
		exlSpeaker: baseUrl + '/exlSpeaker.do',
	},
	speakerSoundsrc: {
		viewSpeakerSoundsrc: baseUrl + '/viewSpeakerSoundsrc.do',
		inqSpeakerSoundsrc: baseUrl + '/inqSpeakerSoundsrc.do',
		inqOneSpeakerSoundsrc: baseUrl + '/inqOneSpeakerSoundsrc.do',
		insSpeakerSoundsrc: baseUrl + '/insSpeakerSoundsrc.do',
		updSpeakerSoundsrc: baseUrl + '/updSpeakerSoundsrc.do',
		delSpeakerSoundsrc: baseUrl + '/delSpeakerSoundsrc.do',
		exlSpeakerSoundsrc: baseUrl + '/exlSpeakerSoundsrc.do',
	},
	speakerCctv: {
		viewSpeakerCctv: baseUrl + '/viewSpeakerCctv.do',
		inqSiteAndSpeakerInfo: baseUrl + '/inqSiteAndSpeakerInfo.do',
		inqAllCctvAndConnectInfoFindBySiteId: baseUrl + '/inqAllCctvAndConnectInfoFindBySiteId.do',
		inqSpeakerCctvFindBySpeakerId: baseUrl + '/inqSpeakerCctvFindBySpeakerId.do',
		updSpeakerApplyInfo: baseUrl + '/updSpeakerApplyInfo.do',
		insSpeakerCctv: baseUrl + '/insSpeakerCctv.do',
	},
	registerUser: {
		viewRegisterUser: baseUrl + '/viewRegisterUser.do',
		inqRegisterUser: baseUrl + '/inqRegisterUser.do',
		inqOneRegisterUser: baseUrl + '/inqOneRegisterUser.do',
		allowRegisterUser: baseUrl + '/allowRegisterUser.do',
		holdRegisterUser: baseUrl + '/holdRegisterUser.do',
		rejectRegisterUser: baseUrl + '/rejectRegisterUser.do',
	},
	cctvBySafeManager: {
		viewCctvBySafeManager: baseUrl + '/viewCctvBySafeManager.do',
		inqCctv: baseUrl + '/inqCctv.do',
		inqOneCctv: baseUrl + '/inqOneCctv.do',
		updCctv: baseUrl + '/updCctv.do',
	},
	speakerAiConfig: {
		viewSpeakerAiConfig: baseUrl + '/viewSpeakerAiConfig.do',
		inqSpeakerInfo: baseUrl + '/inqSpeakerInfo.do',
		inqSpeakerAiConfig: baseUrl + '/inqSpeakerAiConfig.do',
		insSpeakerAiConfig: baseUrl + '/insSpeakerAiConfig.do',
	},
	aiConfigDistigshSutbDgreCctv: {
		viewAiConfigDistigshSutbDgreCctv: baseUrl + '/viewAiConfigDistigshSutbDgreCctv.do',
		inqAiConfigDistigshSutbDgreCctv: baseUrl + '/inqAiConfigDistigshSutbDgreCctv.do',
		saveAiConfigDistigshSutbDgreCctv: baseUrl + '/saveAiConfigDistigshSutbDgreCctv.do',
	},
};

export default {
	path: '/danger-cctv-popup',
	redirect: '/danger-cctv-popup',
	component: () => import('@/views/layout/Default.empty'),
	children: [
		{
			path: '/',
			name: 'dangerCctvPopupHome',
			component: () => import('@/views/dashboard/DangerCctvPopup'),
		},
	],
};

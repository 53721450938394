export default {
	path: '/dashboard-all',
	redirect: '/dashboard-all',
	component: () => import('@/views/layout/DashboardAll'),
	children: [
		{
			path: '/',
			name: 'dashboardAllHome',
			component: () => import('@/views/dashboard/DashboardAll'),
		},
	],
};

export default {
	path: '/site-trespass-config',
	redirect: '/site-trespass-config',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'siteTrespassConfigViewHome',
			component: () => import('@/views/mng/SiteTrespassConfig'),
		},
	],
};

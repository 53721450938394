export default {
	path: '/target',
	redirect: '/target',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'targetHome',
			component: () => import('@/views/mng/Target'),
		},
	],
};

export default {
	path: '/site-statistic',
	redirect: '/site-statistic',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'siteStatisticHome',
			component: () => import('@/views/biz/SiteStatistic'),
		},
	],
};

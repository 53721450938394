export default {
	path: '/basic-ai-config',
	redirect: '/basic-ai-config',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'basicAiConfigHome',
			component: () => import('@/views/mng/BasicAiConfig'),
		},
	],
};

import moment from 'moment';
import config from '@/config';
import api from '@/api';

const GlobalLibraries = {
	install(Vue) {
		function definePropGet(name, obj) {
			Object.defineProperty(Vue.prototype, name, {
				get: () => obj,
			});
		}

		definePropGet('api', api);

		definePropGet('moment_format', (str, fmt) => moment(str).format(fmt));

		definePropGet('$evt', new Vue());
		definePropGet('$config', config);

		Vue.mixin({
			created() {
				if (this.windowResized) {
					window.addEventListener('resize', this.windowResized);
					this.windowResized();
				}
			},

			destroyed() {
				if (this.windowResized) {
					window.removeEventListener('resize', this.windowResized);
				}
			},
		});
	},
};

export default GlobalLibraries;

export default {
	path: '/object-danger-occur-statistic',
	redirect: '/object-danger-occur-statistic',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'objectDangerOccurStatisticHome',
			component: () => import('@/views/biz/ObjectDangerOccurStatistic'),
		},
	],
};

export default {
	path: '/object-danger-occur-statistic-bar',
	redirect: '/object-danger-occur-statistic-bar',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'objectDangerOccurStatisticBarHome',
			component: () => import('@/views/biz/ObjectDangerOccurStatisticBar'),
		},
	],
};

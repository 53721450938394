import moment from 'moment';
import _ from 'lodash';

////////////////////////////
// Axios확장
/** Axios확장 */
const axiosExtention = {
	/**
	 * buildErrorHandler - Axios의 catch error 핸들러 생성
	 * @param fieldMap - 필드-필드명 맵
	 */
	buildErrorHandler(fieldMap) {
		let closure = {
			fieldMap: fieldMap,
		};
		let axiosErrorHandler = function (error) {
			if (error.response) {
				if (error.response && error.response.status == 401) {
					_.defer(function () {
						if (confirm(`로그인이 필요합니다. 로그인페이지로 이동하시겠습니까?`)) {
							window.location.href = '/login';
						}
					});
					return;
				}

				//서버에러응답
				console.error('server error response: ', error.response);
				const data = error.response.data;
				if (data) {
					let msg = /[ㄱ-ㅎ|ㅏ-ㅣ|가-힣]/.test(data.message) ? data.message : `서버의 요청처리에 예외가 발생하였습니다.\n에러메시지: ${data.message}`;
					msg = `${msg}\n\n`;
					let subErrors = data.subErrors || data.errors;
					let subMsg = _.map(subErrors, function (s) {
						let fieldName = s.field || s.objectName;
						if (closure.fieldMap) {
							fieldName = _.has(closure.fieldMap, fieldName) ? closure.fieldMap[fieldName] : fieldName;
						}
						return fieldName + ': ' + (s.message || s.defaultMessage);
					}).join('\n');
					alert(msg + subMsg);
				}
			} else if (error.request) {
				console.log(error.request);
			} else {
				console.log('Error', error.message);
			}
			//console.log(error.config);
		};
		return axiosErrorHandler;
	},
};

////////////////////////////
// Able JS 확장
const ableExtention = {};

////////////////////////////////
// Able JS 확장 - Converter
ableExtention.converter = {
	/**
	 * DateTime포맷을 변경
	 * @param value: 변경할 값
	 * @param parseFormat: 변경전 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 * @param convertFormat: 변경후 Format (기본값: "YYYY-MM-DDTHH:mm:ss.SSSZ")
	 */
	convertDateTimeFormat(value, parseFormat = 'YYYY-MM-DD HH:mm:ss', convertFormat = 'YYYY-MM-DDTHH:mm:ss.SSSZ') {
		if (_.isEmpty(value)) {
			return null;
		}
		let regDateMoment = moment(value, parseFormat);
		if (regDateMoment.isValid()) {
			return regDateMoment.format(convertFormat);
		} else {
			console.log('convertToDateTimeFormat 실패', 'value=' + value, 'parseFormat=' + parseFormat);
			return null;
		}
	},
	/**
	 * 특정 DateTime포맷을 ISO 표준 포맷으로 변경
	 * @param value: 변경할 값
	 * @param parseFormat: 변경전 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 */
	toISODateTime(value, parseFormat = 'YYYY-MM-DD HH:mm:ss') {
		return ableExtention.converter.convertDateTimeFormat(value, parseFormat, 'YYYY-MM-DDTHH:mm:ss.SSSZ');
	},
	/**
	 * DateTime포맷을 ISO 표준 포맷에서 특정 포맷으로 변경
	 * @param value: 변경할 값
	 * @param convertFormat: 변경후 Format (기본값: 'YYYY-MM-DD HH:mm:ss')
	 */
	toDisplayDateTime(value, convertFormat = 'YYYY-MM-DD HH:mm:ss') {
		return ableExtention.converter.convertDateTimeFormat(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', convertFormat);
	},
	/**
	 * Date포맷을 ISO 표준 포맷에서 특정 포맷으로 변경
	 * @param value: 변경할 값
	 * @param convertFormat: 변경후 Format (기본값: 'YYYY-MM-DD')
	 */
	toDisplayDate(value, convertFormat = 'YYYY-MM-DD') {
		return ableExtention.converter.convertDateTimeFormat(value, 'YYYY-MM-DDTHH:mm:ss.SSSZ', convertFormat);
	},
	/**
	 * 주어진 문자열을 moment 객체로 파싱 (허용되는 포맷 대부분의 DateTime형)
	 * @param value 파싱할 값
	 */
	parseMoment(value) {
		var availableFormat = [
			'YYYY-MM-DDTHH:mmZ',
			'YYYY-MM-DDTHH:mm:ssZ',
			'YYYY-MM-DDTHH:mm:ss.SSSZ',
			'YYYY-MM-DD HH:mmZ',
			'YYYY-MM-DD HH:mm:ssZ',
			'YYYY-MM-DD HH:mm:ss.SSSZ',
			'YYYY-MM-DDTHH:mm',
			'YYYY-MM-DDTHH:mm:ss',
			'YYYY-MM-DDTHH:mm:ss.SSS',
			'YYYY-MM-DD HH:mm',
			'YYYY-MM-DD HH:mm:ss',
			'YYYY-MM-DD HH:mm:ss.SSS',
			'YYYY-MM-DD',
		];
		return moment(value, availableFormat, true /*strict*/);
	},
	/**
	 * 주어진 문자열/Boolean을 Boolean으로 변경
	 * @return Boolean: true/false, String:'true'/'t'/'1' 이외에 false
	 */
	asBoolean(value) {
		if (_.isBoolean(value)) {
			return value;
		}
		if (_.isString(value)) {
			return _.contains(['true', 't', '1'], value);
		}
		return false;
	},
	/**
	 * 숫자를 numberFormatting
	 * @param value: 변경할 값
	 */
	toNumberFormat(value) {
		var convertedValue = String.toNumber(value);
		if (!_.isNaN(convertedValue)) {
			return String.numberFormat(convertedValue);
		}
		return value;
	},
};

export default {
	axiosExtention,
	ableExtention,
};

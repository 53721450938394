export default {
	path: '/new-main',
	redirect: '/new-main',
	component: () => import('@/views/layout/Default.empty'),
	children: [
		{
			path: '/',
			name: 'newMainHome',
			component: () => import('@/views/main/NewMain'),
		},
	],
};

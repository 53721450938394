export default {
	path: '/exemplary-case',
	redirect: '/exemplary-case',
	component: () => import('@/views/layout/Default'),
	children: [
		{
			path: '/',
			name: 'exemplaryCaseHome',
			component: () => import('@/views/biz/ExemplaryCase'),
		},
	],
};

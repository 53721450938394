import Vue from 'vue';
import VueRouter from 'vue-router';

// dashboard Router
import Dashboard from './dashboard/dashboard';
import DashboardAll from './dashboard/dashboardAll';
import DangerCctvPopup from './dashboard/dangerCctvPopup';

// main Router
import Login from './main/login';
import Main from './main/main';
import IronMain from './main/ironMain';
import RenewMain from './main/renewMain';
import newMain from './main/newMain';
import RecentlyNotice from './main/recentlyNotice';

// biz Router
import AccidentPrevent from './biz/accidentPrevent';
import WarnWorkOccur from './biz/warnWorkOccur';
import WarnWorkOccurVideo from './biz/warnWorkOccurVideo';
import DangerOccur from './biz/dangerOccur';
import DangerOccurVideo from './biz/dangerOccurVideo';
import SiteStatistic from './biz/siteStatistic';
import ObjectDangerOccurStatistic from './biz/objectDangerOccurStatistic';
import ObjectDangerOccurStatisticBar from './biz/objectDangerOccurStatisticBar';
import ObjectDangerOccurStatisticBar2 from './biz/objectDangerOccurStatisticBar2';
import ObjectDangerOccurStatisticLine2 from './biz/objectDangerOccurStatisticLine2';
import ObjectWarnWorkOccurStatisticBar from './biz/objectWarnWorkOccurStatisticBar';
import AccidentStatistic from './biz/accidentStatistic';
import ExemplaryCase from './biz/exemplaryCase';
import AiDistinguish from './biz/aiDistinguish';

// mng Router
import Site from './mng/site';
import Company from './mng/company';
import User from './mng/user';
import Menu from './mng/menu';
import RoleGroup from './mng/roleGroup';
import GrpCode from './mng/grpCode';
import CommCode from './mng/commCode';
import Object from './mng/object';
import Target from './mng/target';
import ObjectTarget from './mng/objectTarget';
import WarnWork from './mng/warnWork';
import Cctv from './mng/cctv';
import BasicAiConfig from './mng/basicAiConfig';
import CctvAiConfig from './mng/cctvAiConfig';
import ApiLogCallErr from './mng/apiCallErrLog';
import TreeView from './mng/treeView';
import Notice from './mng/notice';
import CctvDangerAreaConfig from './mng/cctvDangerAreaConfig';
import SiteTrespassConfig from './mng/siteTrespassConfig';
import SettingPushAlarm from './mng/settingPushAlarm';
import Speaker from './mng/speaker';
import SpeakerSoundsrc from './mng/speakerSoundsrc';
import SpeakerCctv from './mng/speakerCctv';
import RegisterUser from './mng/registerUser';
import CctvBySafeManager from './mng/cctvBySafeManager';
import SpeakerAiConfig from './mng/speakerAiConfig';
import AiConfigDistigshSutbDgreCctv from './mng/AiConfigDistigshSutbDgreCctv';
import DangerWarnWorkOccur from './biz/dangerWarnWorkOccur';
import KccRestApiLogin from './main/kccRestApiLogin';

Vue.use(VueRouter);

const routes = [
	{
		path: '/',
		redirect: '/login',
	},
];

const router = new VueRouter({
	mode: 'history',
	base: process.env.BASE_URL,
	routes: [
		...routes,
		// dashboard Router
		Dashboard,
		DangerCctvPopup,
		DashboardAll,
		// main Router
		Login,
		Main,
		IronMain,
		RenewMain,
		newMain,
		RecentlyNotice,
		KccRestApiLogin,
		// biz Router
		AccidentPrevent,
		WarnWorkOccur,
		WarnWorkOccurVideo,
		DangerOccur,
		DangerOccurVideo,
		DangerWarnWorkOccur,
		SiteStatistic,
		AccidentStatistic,
		ObjectDangerOccurStatistic,
		ObjectDangerOccurStatisticBar,
		ObjectDangerOccurStatisticBar2,
		ObjectDangerOccurStatisticLine2,
		ObjectWarnWorkOccurStatisticBar,
		ExemplaryCase,
		AiDistinguish,
		// mng Router
		Site,
		Company,
		User,
		Menu,
		RoleGroup,
		GrpCode,
		CommCode,
		Object,
		ObjectTarget,
		Target,
		WarnWork,
		Cctv,
		BasicAiConfig,
		CctvAiConfig,
		ApiLogCallErr,
		TreeView,
		SettingPushAlarm,
		Notice,
		CctvDangerAreaConfig,
		SiteTrespassConfig,
		Speaker,
		SpeakerSoundsrc,
		SpeakerCctv,
		RegisterUser,
		CctvBySafeManager,
		SpeakerAiConfig,
		AiConfigDistigshSutbDgreCctv,
	],
});

export default router;
